import React from "react";
import { graphql } from "gatsby";

import { Container, Button } from "react-bootstrap";

import { useShop } from "../context/ShopContext";
import Layout from "../components/common/Layout";
import SliceZone from "../components/common/SliceZone";
import ProductSlider from "../components/elements/ProductSlider";
import Anchor from "../components/elements/Anchor";
import ReviewsBanner from "../components/common/ReviewsBanner";

// markup
const IndexPage = ({ data }) => {
  if (!data) return null;

  const slices = data.prismicHomepage?.data?.body;
  const products = data.allPrismicProduct?.edges;
  const { allProducts } = useShop();

  return (
    <Layout>
      <SliceZone slices={slices} />
      <article className="section-all-menu mb-6">
        <Container>
          <div className="pb-4 text-center">
            <h2 className="h4 text-primary">ΣΥΝΤΑΓΕΣ</h2>
            <p className="h5">Τα γεύματα μου</p>
          </div>
          <ProductSlider data={products} />
          <div className="mt-5 text-center">
            <Button as={Anchor} href={allProducts}>
              Δες τις Συνταγές
            </Button>
          </div>
        </Container>
      </article>
      <ReviewsBanner />
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery {
    prismicHomepage {
      ...prismicHomepageFragment
    }
    allPrismicProduct(limit: 4) {
      edges {
        node {
          ...prismicCardProductFragment
        }
      }
    }
  }
`;

export default IndexPage;
