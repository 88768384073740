import React from "react";

import { Container, Stack } from "react-bootstrap";

import { StarOutline } from "../../helpers/Icons";

const ReviewsBanner = () => {
  const reviewsCount = 8000;

  return (
    <article
      className="reviews-banner mb-6 py-6 bg-primary"
      style={{ "--bs-bg-opacity": "0.1" }}
    >
      <Container className="text-center">
        <h2 className="h4 mb-4 fw-bold text-primary">
          Over 1 million meals cooked and loved
        </h2>
        <p className="h4 d-flex flex-column flex-sm-row align-items-center justify-content-center">
          <span className="d-flex flex-column flex-sm-row align-items-center justify-content-center fw-bold">
            Excellent
            <Stack
              as="span"
              direction="horizontal"
              gap={2}
              className="ms-3 me-4"
              aria-label="5 star rating"
              role="img"
            >
              <StarOutline />
              <StarOutline />
              <StarOutline />
              <StarOutline />
              <StarOutline />
            </Stack>
          </span>
          {reviewsCount} reviews
        </p>
      </Container>
    </article>
  );
};

export default ReviewsBanner;
